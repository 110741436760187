import React from 'react';

// Styles
import '../../styles/investments-plan-summary.css';
import '../../styles/layout.css';

import DismissContainer from '../DismissContainer';
export default class Dropdown extends React.Component {

    state = {
        open: false,
        search: "",
    }

    render = () => {
        return (
            <div style={{...(this.props.style?.marginRight?{marginRight:this.props.style.marginRight}: {})}}>
                <div className='a'
                    onClick={() => this.setState({open: !this.state.open})}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 7,
                    }}
                >
                    {(this.props.showArrow??true )&&
                        <img 
                            src={`https://static.stax.ai/images/icons/down-arrow.svg`}
                            width={20}
                            height={20}
                            alt=""
                            style={{
                                transition: 'transform 0.2s ease-in-out',
                                transform: this.state.open ? 'rotate(180deg)' : 'rotate(0deg)',
                            }}
                        />
                    }

                    {this.props.options.find((option) => option.value === this.props.value)?.label || 'Select'}
                </div>
                {
                    this.state.open &&
                    <DismissContainer
                        onClose={() => {this.setState({open: false})}}  
                    >
                        <div
                            className='contextmenu-menu contextmenu-menu-opened'
                            style={{position: 'absolute', zIndex: 99, paddingTop: 0, minWidth: 150}}
                        >
                            {
                                this.props.searchable? 
                                <div
                                    style={{
                                        position: 'sticky',
                                        top: 0,
                                        zIndex: 99, 
                                        height: 36,
                                        backgroundColor: 'var(--color-menu-raised)',
                                        padding: 10, 
                                    }}
                                >
                                    <input 
                                        type='text'
                                        className='user-menu-team-filter'
                                        placeholder='Find account...'
                                        value={this.state.search}
                                        onChange={(e) => this.setState({search: e.target.value})}
                                        autoFocus
                                    />
                                </div>:null
                            }
                           
                            {this.props.options.filter(o => {
                                if(o.value === this.props.value)
                                    return false;
                                if(!this.props.searchable)
                                    return true;  
                                return o.searchTerm.toLowerCase().includes(this.state.search.toLowerCase());
                            }).map((option) => {
                                return (
                                    <div
                                        className='contextmenu-option'
                                        style={{paddingRight: 20}}
                                        onClick={() => {
                                            this.props.onChange(option.value);
                                            this.setState({open: false});
                                        }}
                                    >
                                        {option.label}
                                    </div>
                                )
                            })}
                        </div>
                    </DismissContainer>
                }
            </div>
        );
    }
}