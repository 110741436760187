const labelMapping = {
    beginningBalance: 'Opening Balance',
    deposits: 'Deposits',
    withdrawals: 'Withdrawals',
    loanPayments: 'Loan Payments',
    income: 'Income',
    fees: 'Fees',
    transfers: 'Transfers',
    rollovers: 'Rollovers',
    reinvestments: 'Reinvestments',
    other: 'Other Transactions',
    gain_loss: 'Gain/(loss)',
    endingBalance: 'Closing Balance',
    accrued: 'Accrued Income',
    balanceAccrued: 'Balance + Accruals'
}

const metadataMapping = {
    beginningBalance: 'beginning balance',
    deposits: 'tx: deposits',
    withdrawals: 'tx: withdrawals',
    loanPayments: 'tx: Loan Payments',
    income: 'tx: income',
    fees: 'tx: fees',
    transfers: 'tx: transfers',
    other: 'tx: other',
    gain_loss: 'gain/loss',
    endingBalance: 'ending balance',
    accrued: 'tx: accrued',
    balanceAccrued: 'Balance + Accruals',
    purchases: 'tx: purchases',
    sales: 'tx: sales',
    reinvestments: 'tx: reinvestments',

}

const transactionTypeMapping = {
    deposits: "Deposit",
    fees: "Fees",
    income: "Income",
    withdrawals: "Withdrawal",
    other: "Other transaction",
    reinvestments: "Reinvestment",
    loanPayments: "Loan Payment",
    transfers: "Transfer",
    receivable: "Receivable",
    rollovers: "Rollover",
    accrued: "Accrued Income",
}


const reconciles = (statement) => {
    if(statement.confirmed)
        return true; 

    const {beginningBalance:beginning, endingBalance:ending} = statement; 
    const {
        deposits, 
        withdrawals, 
        income, 
        fees, 
        transfers, 
        reinvestments,
        other, 
        gain_loss,
        loanPayments, 
        rollovers,
    } = statement.tx;

    for (const lhs of [
        // beginning + deposits + withdrawals + income + fees + other + transfers + gain + accrued,
        beginning + deposits + withdrawals + income + fees + other + transfers + gain_loss + reinvestments + loanPayments + rollovers,
    ]) {
        try {
            if (lhs.toFixed(2).replace('-0', '0') === ending.toFixed(2).replace('-0', '0'))
                return true;
        } catch {}
    }

    return false; // Failed to reconcile
}

const calculateGainLoss = (endingBalance, beginningBalance, transactions) => {
    const {
        deposits, 
        withdrawals,
        income, 
        fees, 
        transfers, 
        other,
        reinvestments,
        loanPayments,
        rollovers,
        recievables, 
    } = transactions;

    return (endingBalance??0) - (
        (beginningBalance??0) +
        (deposits??0) +
        (withdrawals??0) +
        (income??0) +
        (fees??0) +
        (transfers??0) +
        (other??0) +
        (reinvestments??0) +
        (loanPayments??0) +
        (rollovers??0) +
        (recievables??0)
    );
}

const applyStatementDiff = (statements) => {
    // Get the statements... 
    statements = [...statements].map(s => {
        const txDetails = {...s.tx}; 
        const diff = {...s.diff}; 

        // We need to apply the diff to the statement...
        Object.keys(diff).forEach((key) => {
            txDetails[key] = (txDetails[key]??0) + diff[key];
        });

        s = {
            ...s, tx: txDetails
        };

        // Lets double check if it reconciles. We may have made changes, and need to take everything into account.  
        s.reconciles = reconciles(s) 

        return {...s, tx: txDetails}; 
    });

    return statements; 
}

const reduceStatements = (statements, plan, transactions=[], detail) => {
    if(!statements || !plan) return []; 
   
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']; 
    let startMmIndex = (plan.endMm??12) % 12;
    months = [...months.slice(startMmIndex), ...months.slice(0, startMmIndex)];

    statements = applyStatementDiff([...(statements??[])]).sort((a, b) => {
        const minMonthA = Math.max(...a.months.map(m => months.indexOf(m)));
        const minMonthB = Math.max(...b.months.map(m => months.indexOf(m)));
        return minMonthA - minMonthB; 
    });

    // - Find the best statement coverage... 
    statements = statements.reduce((acc, statement, index) => {
        let statementMonths = (statement.months??[]).sort((a, b) => months.indexOf(a) - months.indexOf(b));
        if(!statementMonths.length) return acc; 
        
        let columnKey = statementMonths.length > 1 ? `${statementMonths[0]} - ${statementMonths[statementMonths.length - 1]}` : statementMonths[0];

        // Is this already covered? (Check for a duplicate statements) (This should have been resolved on April 10th, 2024)
        if(acc.some(s => s.columnKey === columnKey)){
            // are we wanting to combine?  // Plan page... 
            return acc;
        }

        // Check if smaller statements cover this statement... 
        const currentCoverage = acc.reduce((months, s) => [...months, ...s.months], []); 
        if(statementMonths.every(m => currentCoverage.includes(m)))
            return acc; 

        // Ensure that the statement months are in the correct order... 
        statementMonths.sort((a, b) => months.indexOf(a) - months.indexOf(b)); 

        // If this is a multi month statement, we need to check if we can even use it... 
        if(!statementMonths.reduce((acc, m, i) => {
            if(!acc.continue) return acc; // Something already failed. 

            // check if this month is covered by a previous statement.  
            const covered = currentCoverage.some(c => c === m); 

            // If we are covered here, but the previous was not, don't continue. We have a gap, and should not use this statement. 
            if(covered && !acc.previous) return {continue: false, previous: true}; 

            // We can continue
            return {continue: true, previous: covered};

        }, {continue: true, previous: true}).continue)
            return acc; 
        
        // Do we need to consider splitting this statement? (Multi month, with partial coverage already) 
        const split = currentCoverage.some(c => statementMonths.includes(c)); 

        if(split){
            const refs = {...statement.ref??{}, ...statement.ref?.tx??{}, tx: ""}
            statement.original = {...statement, ...statement.tx}
            const isQuarterly = Object.values(refs).some(r => r?.includes("Quarter"));
            if(isQuarterly){
                // Lets make sure the coverage is set for the whole quarter, so we know what to split. 
                const standardMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']; 
                const monthIndex = standardMonths.indexOf(statementMonths[statementMonths.length -1]);
                const quarter = Math.ceil((monthIndex + 1) / 3);
                statementMonths = standardMonths.slice(quarter * 3 - 3, monthIndex + 1);
            }

            // Lets split this statement. 
            let newCoverage = [...statementMonths.filter(m => !currentCoverage.includes(m))]; // Are we breaking down a year statement? Keep only what not covered.  

            // We need to go through each month, and remove the previous statement's value. 
            statementMonths.reduce((c, m) => {
                if(!c.continue) return c; 

                // Find the statement that covers this month. 
                const s = acc.find(s => s.months.includes(m)); 
                if(!s) return {continue: false, statement: null}; 

                // If we used this already (Quarterly, splitting a yearly) skip. 
                if(c.statement === s._id) return c;  
                
                // Update the beginning balance, 
                statement.beginningBalance = s.endingBalance;  

                // Subtract all other fields... 
                Object.keys(labelMapping).forEach((key) => {
                    // We need to skip some fields. 
                    if(['beginningBalance', 'endingBalance', 'gain_loss'].includes(key)) return; // Gain loss will be calculated later. \

                    // Check the ref, if it is Since last statement, keep the value. else, subtract. 
                    if(((refs[key] && refs[key]?.toLowerCase().includes('last statement') )|| refs[key]?.toLowerCase().includes('current month'))){
                        return; 
                    };

                    statement.tx[key] = (statement.tx[key]??0) - (s[key]??0); 
                    statement.calculated = {
                        ...statement.calculated??{},
                        [key]: true
                    }
                    statement.splitFrom = [...new Set([...statement.splitFrom??[], s.months])];
                })

                return {continue: true, statement: s._id};
            }, {continue: true, statement: null});
            newCoverage = newCoverage.sort((a, b) => months.indexOf(a) - months.indexOf(b));
            statement.months = newCoverage; 
            statement.split = statement.splitFrom?.length;
            statement.tx.gain_loss = calculateGainLoss(statement.endingBalance, statement.beginningBalance, statement.tx);
            columnKey = newCoverage.length > 1 ? `${newCoverage[0]} - ${newCoverage[newCoverage.length - 1]}` : newCoverage[0]; 
        }

        return [...acc, {
            ...statement, 
            ...statement.tx, // Flatten the statement and bring transactions to the root level.
            balanceAccrued: (statement.endingBalance??0) + (statement.tx.accrued??0), 
            columnKey, 
        }]
    }, []);


    // Now that we have each statement, lets check for any errors.  
    statements = statements.map((s, statementIdx) => {
        const previousStatement = statementIdx > 0? statements[statementIdx - 1]: null; 
        const nextStatement = statementIdx < statements.length - 1? statements[statementIdx + 1]: null; 

        // Lets check for any errors...  
        const errors = []; 
        if(previousStatement){
            // Check the previous statement's ending balance, matches this statement's beginning balance. 
            if(previousStatement.endingBalance !== s.beginningBalance)
                errors.push({
                    accountNumber: s.accountNumber, 
                    key: 'beginningBalance',
                    level: 'high'
                })
        }

        if(nextStatement){
            if(nextStatement.beginningBalance !== s.endingBalance)
                errors.push({
                    accountNumber: s.accountNumber, 
                    key: 'endingBalance',
                    level: 'high'
                })
        }

        if(!s.reconciles)
            errors.push({
                accountNumber: s.accountNumber, 
                key: 'reconciles',
                level: 'high'
            })
        
        const txKeyCheck = [
            ...(detail === 'All' ? ['deposits', 'withdrawals', 'income', 'fees', 'transfers'] : []),
            ...(detail === 'Cashflow' ? ['deposits', 'withdrawals', 'transfers'] : []),   
        ]

        if (txKeyCheck.length > 0) {
            const statementTransactions = transactions.filter(t => t.statement === s._id);

            for (const key of txKeyCheck) {
                if(s.accepted?.[txKeyCheck]) continue;
                // Sum all transactions that are categorized as this key
                const sum = statementTransactions.reduce((acc, t) => {
                    if (t.txType !== transactionTypeMapping[key]) return acc;
                    return acc + t.amount;
                }, 0);

                if (sum.toFixed(2) !== s[key].toFixed(2)) {
                    errors.push({
                        accountNumber: s.accountNumber,
                        key, 
                        level: 'high'
                    });
                }
            }
        }

        return {
            ...s, 
            errors, 
        }
    });

    return statements;
}

const combineStatements = (one, two) => {
    const combined = {...one, accountNumber: 'combined'}; 
    Object.keys(labelMapping).forEach((key) => {
        // Some keys we need to ignore... 
        combined[key] = (one[key]??0) + (two[key]??0); 
    })

    combined.errors = [...one.errors, ...two.errors]; 
    combined.reconciles = one.reconciles && two.reconciles;
    combined.confirmed = one.confirmed && two.confirmed;  
    combined.statements = [...(combined.statements??[combined._id]), ...(two.statements??[two._id])];
    
    return combined; 
}

const expectedCoverage = (statements, plan, year) => {
    // Lets figure out what month coverage we are expecting... 
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let expectedCoverage = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let startMmIndex = (plan.endMm??12) % 12;
    months = [...months.slice(startMmIndex), ...months.slice(0, startMmIndex)]
    expectedCoverage = JSON.parse(JSON.stringify(months))

    if(!statements.length)
        return expectedCoverage; 

    // What is today's date? Used to determine if we should be expecting a statement or not...
    const today = new Date(); 
    const monthToday = today.toLocaleString('en-US', { month: 'short' }); 
    const yearToday = today.getUTCFullYear(); 

    // Remove any months we should not be expecting...
    if(yearToday === year){
        // Remove any months that are in the future...
        let monthIdx = expectedCoverage.indexOf(monthToday) -1;
        if(monthIdx < 0) monthIdx = 11; // If we are in January, we need to remove December. Copilot - 04/26/2024
        if(monthIdx >= 0)
            expectedCoverage = expectedCoverage.slice(0, monthIdx + 1);
        else 
            expectedCoverage = [];
    }

    // Now lets check for beginning/ending balance 0, and set unexpected coverage... 
    const byBeginningBalance = [...statements].sort((a, b) => {
        if(a.beginningBalance === b.beginningBalance)
            return months.indexOf(a.months[0]) - months.indexOf(b.months[0]);
        return a.beginningBalance - b.beginningBalance
    });
    const byEndingBalance = [...statements].filter(s => s.endingBalance === 0).sort((a, b) => { 
        if(a.endingBalance === b.endingBalance)
            return months.indexOf(a.months[0]) - months.indexOf(b.months[0]);
        return a.endingBalance - b.endingBalance
    });

    if(byBeginningBalance[0].beginningBalance === 0){
        const check = statements.findIndex(s => s._id === byBeginningBalance[0]._id);
        // If this is the first statement we have, then remove any prior months 
        if(check === 0){
            const firstMonth = statements[0].months[0]; 
            const idx = expectedCoverage.indexOf(firstMonth);
            expectedCoverage = expectedCoverage.slice(idx);
        }
    }   

    if(byEndingBalance.length){
        
        const check = statements.findIndex(s => s._id === byEndingBalance[byEndingBalance.length -1]._id);
        // If this is the last statement we have, then remove any future months
        if(check === statements.length -1){
            const lastMonth = statements[statements.length -1].months[0];
            const idx = expectedCoverage.indexOf(lastMonth);

            expectedCoverage = expectedCoverage.slice(0, idx + 1);
        }
    }

    return expectedCoverage; 
}


export default {
    labelMapping,
    metadataMapping,
    transactionTypeMapping,
    reconciles,
    calculateGainLoss,
    applyStatementDiff,
    reduceStatements,
    combineStatements,
    expectedCoverage,
}